import { CHECKOUT_TOTAL_UPDATE, CHECKOUT_UPDATE } from '../../actions/checkout'
import { CheckoutRequest } from 'utils/types/payment'

const initialState = {
  type: '',
  info: {},
  total: 0,
}

const appReducer = (state = initialState, action: { type: string; value: CheckoutRequest }) => {
  switch (action.type) {
    case CHECKOUT_UPDATE:
      return action.value
    case CHECKOUT_TOTAL_UPDATE:
      return { ...state, total: action.value }
    default:
      return state
  }
}

export default appReducer
