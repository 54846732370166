import { combineReducers } from 'redux'
import auth from './auth/'
import app from './app'
import checkout from './checkout'

const rootReducer = combineReducers({
  app,
  auth,
  checkout,
})

export default rootReducer
