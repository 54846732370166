import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { socketManager } from '../socket-io'
import { setLanguage } from 'redux/actions/auth/userActions'

const Context = React.createContext()

const IntlProviderWrapper = (props) => {
  const user = useSelector(({ auth }) => auth.user)

  const getActiveLanguage = () => {
    //priority to the url language
    const firstPath = location.pathname.split('/')[1]

    if (firstPath === 'es') {
      return firstPath
    }

    //default language
    let language = 'en'

    //load user defined language if logged in
    if (user.loggedIn) {
      language = user.settings.language
    } else {
      //if not load from storage
      const localStorageLang = localStorage.getItem('language')

      if (localStorageLang) {
        language = localStorageLang
      }
    }

    //only show es language on url, default en
    if (language === 'es') {
      const searchParams = new URLSearchParams(location.search)
      const searchParamsString = searchParams.toString()
      history.replaceState(
        null,
        '',
        `/es${location.pathname}${searchParamsString ? `?${searchParamsString}` : ''}`,
      )
    } else if (language === 'en' && firstPath === 'en') {
      const pathnameWithoutEN = location.pathname.split('/en')[1]

      const searchParams = new URLSearchParams(location.search)
      const searchParamsString = searchParams.toString()
      history.replaceState(
        null,
        '',
        `${pathnameWithoutEN}${searchParamsString ? `?${searchParamsString}` : ''}`,
      )
    }

    return language
  }

  const activeLanguage = getActiveLanguage()

  const [locale, setLocale] = useState(activeLanguage)
  const [messages, setMessages] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    loadCatalog(activeLanguage)
  }, [activeLanguage])

  const loadCatalog = async (language) => {
    let messages = await import(`../assets/locales/${language}.json`)

    setLocale(language)
    setMessages(messages)
  }

  const handleSwitchLanguage = (language) => {
    loadCatalog(language)
    localStorage.setItem('language', language)

    dispatch(setLanguage(language))
    //save to db
    if (user.loggedIn) {
      socketManager.send('set-language', language)
    }

    const searchParams = new URLSearchParams(location.search)
    const searchParamsString = searchParams.toString()

    //only show es language on url, default en
    if (language === 'es') {
      //change basepath
      history.replaceState(
        null,
        '',
        `/es${location.pathname}${searchParamsString ? `?${searchParamsString}` : ''}`,
      )
    } else {
      const pathnames = location.pathname.split('/es')
      pathnames.shift()
      history.replaceState(
        null,
        '',
        `${pathnames.join('/')}${searchParamsString ? `?${searchParamsString}` : ''}`,
      )
    }
  }

  const { children } = props

  return (
    <Context.Provider
      value={{
        state: { locale, messages },
        switchLanguage: handleSwitchLanguage,
      }}
    >
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale='en'
        onError={() => null}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
