import { socketManager } from 'socket-io'
import jwtDecode from 'jwt-decode'
import { store } from 'redux/storeConfig/store'
import { logoutUser, setUserData } from 'redux/actions/auth/userActions'
import EventEmiter from '../utils/EventEmiter'

//TODO set proper types in this file
class authService extends EventEmiter {
  // private
  // @ts-ignore
  #sessionTimeout = null

  // Sheduled the logout event
  // @ts-ignore
  #scheduleLogout = (access_token: string) => {
    const decoded = jwtDecode(access_token) as { exp: number }
    const expirationTime = decoded.exp * 1000
    const currentTime = Date.now()

    if (expirationTime >= currentTime) {
      // Define the alert threshold to be 10 minutes before expiration
      const alertThreshold = 10 * 60 * 1000 // 10 minutes in milliseconds

      let timeToLogout = expirationTime - currentTime - alertThreshold //expiration time minus 10 min

      if (this.#sessionTimeout !== null) {
        clearTimeout(this.#sessionTimeout)
      }

      // @ts-ignore
      this.#sessionTimeout = setTimeout(() => {
        this.#deleteSession()
        this.emit('sessionExpired')
      }, timeToLogout)
    }
  }

  // @ts-ignore
  #saveSession = (user: any, access_token: string) => {
    localStorage.setItem('_at', access_token)
    store.dispatch(setUserData(user))
    this.#scheduleLogout(access_token)
  }

  // @ts-ignore
  #deleteSession = () => {
    this.logout()
  }

  // public
  register = (data: any) => {
    return socketManager.send('auth-register', data)
  }

  confirmEmail = (data: any) => {
    return socketManager.send('auth-confirm-email', data)
  }

  resendCode = (data: any) => {
    return socketManager.send('auth-resend-code', data)
  }

  forgotPassword = (data: any) => {
    return socketManager.send('forgot-password', data)
  }

  forgotPasswordReset = (data: any) => {
    return socketManager.send('forgot-password-reset', data)
  }

  loginWithEmailPass = async (data: any) => {
    try {
      const res = await socketManager.send('auth-login', data)

      if (res.user) {
        this.#saveSession(res.user, res.access_token)
      }

      return res
    } catch (e) {
      console.error(e)
    }
  }

  logout = () => {
    localStorage.removeItem('_at')
    store.dispatch(logoutUser())
    socketManager.send('auth-logout')
  }

  connect = async () => {
    const at = localStorage.getItem('_at')
    if (!at) {
      return 'no-at'
    } else {
      const decoded = jwtDecode(at) as { exp: number }
      const currentTime = Date.now() / 1000

      if (decoded.exp >= currentTime) {
        //ssocketManager.connect()
        this.#scheduleLogout(at)
        return 'login'
      } else {
        this.logout()
        return 'logout'
      }
    }
  }
}

const instance = new authService()

export default instance
