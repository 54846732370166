import { CheckoutRequest } from 'utils/types/payment'

export const CHECKOUT_UPDATE = '[CHECKOUT] UPDATE'
export const CHECKOUT_TOTAL_UPDATE = '[CHECKOUT] TOTAL UPDATE'

interface DispatchUpdateCheckout {
  type: string
  value: CheckoutRequest
}

interface DispatchUpdateTotal {
  type: string
  value: number
}

export const updateCheckout = (value: CheckoutRequest) => {
  return { type: CHECKOUT_UPDATE, value }
}

export const updateCheckoutTotal = (value: number) => {
  return { type: CHECKOUT_TOTAL_UPDATE, value }
}
