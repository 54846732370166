import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
//
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'rc-slider/assets/index.css'
// STYLE
import './assets/styles/index.scss'
import './index.css'
import './assets/fonts/line-awesome-1.3.0/css/line-awesome.css'

//
import reportWebVitals from './reportWebVitals'

// Socket IO
import { SocketProvider } from './socket-io'

// Redux
import { store } from './redux/storeConfig/store'
import { Provider } from 'react-redux'

// Spinner
import LoadingSpinner from 'components/@shared/LoadingSpinner/LoadingSpinner'

// Internationalization
import { IntlProviderWrapper } from 'context/Internationalization'

// Lazy App
const LazyApp = lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <SocketProvider>
    <Provider store={store}>
      <Suspense fallback={<LoadingSpinner />}>
        <IntlProviderWrapper>
          <LazyApp />
        </IntlProviderWrapper>
      </Suspense>
    </Provider>
  </SocketProvider>,
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
