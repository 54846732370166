import React from 'react'
import socketManager from './SocketManager'

const SocketContext = React.createContext(socketManager)

const SocketProvider = ({ children }) => {
  return <SocketContext.Provider value={socketManager}>{children}</SocketContext.Provider>
}

export default SocketProvider
