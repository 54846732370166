import socket from './socket'
import { store } from 'redux/storeConfig/store'
import { setUserData } from '../redux/actions/auth/userActions'
/*
 * Socket.IO Manager class
 */

class SocketManager {
  authorizing = false
  reconnecting = false

  connectAllowed = true
  poolAuthorizing = []
  poolConnected = []

  constructor() {
    socket.on('connect', () => {
      console.log('c', this.reconnecting)
      /*if (this.connectAllowed) {
        this.connectAllowed = false

        const access_token = this.getAccessToken()
        if (access_token) {
          this.authorize(access_token)
        } else {
          this.poolConnected = this.processPool(this.poolConnected)
        }


      }*/
      /*if (this.reconnecting) {
        this.reconnecting = false
        this.connect(true)
      }*/

      const access_token = this.getAccessToken()
      if (access_token) {
        this.authorize(access_token)

        if (this.reconnecting) {
          this.poolConnected = this.processPool(this.poolConnected)
        }
      } else {
        this.poolConnected = this.processPool(this.poolConnected)
      }
    })

    socket.on('disconnect', (reason) => {
      console.log('d', reason)
      this.reconnecting = true
      this.connectAllowed = true
    })
  }

  connect = (reconnecting = false) => {
    console.log('ccc', this.connectAllowed, reconnecting)
    if (this.connectAllowed) {
      this.connectAllowed = false
    }
  }

  reconnect = () => {
    console.log('re')
    this.reconnecting = true
    this.connectAllowed = true
    //socket.connect()
  }

  getAccessToken = () => {
    return window.localStorage?.getItem('_at')
  }

  deleteAccessToken = () => {
    return window.localStorage?.removeItem('_at')
  }

  processPool(pool) {
    console.log('pp', pool.length)

    for (const pendingEvent of pool) {
      socket.emit(pendingEvent.event, pendingEvent.data, (res) => {
        pendingEvent.resolve(res)
      })
    }

    return []
  }

  checkConnected() {
    if (socket.disconnected) {
      this.reconnect()
      return false
    }
    return true
  }

  authorize(accessToken) {
    console.log('a', this.authorizing)

    //if (!this.authorizing) {
    this.authorizing = true

    this.send('auth-login-token', { access_token: accessToken }).then((res) => {
      if (res.user) {
        store.dispatch(setUserData(res.user))

        this.authorizing = false
        this.poolConnected = this.processPool(this.poolConnected)
        this.poolAuthorizing = this.processPool(this.poolAuthorizing)
      } else if (res.tokenNotValid) {
        this.deleteAccessToken()
        this.authorizing = false
        this.poolConnected = []
        this.poolAuthorizing = []
      }
    })
    //}
  }

  unauthorize() {
    socket.disconnect()
  }

  send(event, data) {
    return new Promise((resolve) => {
      const connected = this.checkConnected()

      if (event === 'auth-login') {
        console.log('l', connected)
      }

      if (event === 'auth-login-token') {
        console.log('alt', connected)
      }

      if (!connected) {
        this.poolConnected.push({
          event,
          data,
          resolve,
        })
      } else if (this.authorizing && event !== 'auth-login-token') {
        this.poolAuthorizing.push({
          event,
          data,
          resolve,
        })
      } else {
        if (event === 'auth-login') {
          console.log('el')
        }

        if (event === 'auth-login-token') {
          console.log('ealt')
        }
        socket.emit(event, data, (response) => {
          if (event === 'auth-login') {
            console.log('rl')
          }

          if (event === 'auth-login-token') {
            console.log('ralt')
          }
          resolve(response)
        })
      }
    })
  }

  subscribe(event, callback) {
    this.checkConnected()
    socket.on(event, callback)
  }

  unsubscribe(event) {
    socket.off(event)
  }

  close() {
    socket.close()
  }
}

const socketManager = new SocketManager(socket)

export default socketManager
