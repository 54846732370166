import { io } from 'socket.io-client'

/*
 * Socket.IO instance
 */
const socket = io({
  //transports: ['websocket', 'polling'],
  forceNew: true,
})

socket.on('connect_error', (e) => {
  console.log('ce', e)
  setTimeout(() => {
    console.log('re attempt')
    socket.connect()
  }, 1000)
})

export default socket
