import PuffLoader from 'react-spinners/PuffLoader'

//const color = 'rgb(79, 70, 229)'
const color = 'rgb(6, 182, 212)'

const LoadingSpinner = ({ className }: { className?: string }) => {
  return (
    <div className={`sweet-loading ${className ? className : ''}`}>
      <PuffLoader color={color} loading={true} />
    </div>
  )
}

export default LoadingSpinner
